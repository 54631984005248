<template>
	<div></div>
</template>

<script>
export default {
	data() {
		return {
			paymentUrl: '/kiwoom/popup',
		};
	},
	methods: {},
	mounted() {
		const success = this.$route.query.success;
		const close = this.$route.query.close;
		if (close) {
			if (success) {
				window.parent.postMessage('success', '*');
			} else {
				window.parent.postMessage('close', '*');
			}
		}
		return;
	},
};
</script>

<style></style>
